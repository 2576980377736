<template>
  <NavBar title="评论详情" />
  <TopComment />
  <CommentReplyItem :comments-list="commentReply" />
  <BottomOperator />
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import NavBar from "../../components/navbar/index.vue";
import TopComment from "./components/TopComment.vue";
import CommentReplyItem from "../../components/comments/CommentItem.vue";
import BottomOperator from "./components/BottomOperator.vue";
import { useStore } from "vuex";

export default {
  components: {
    NavBar,
    TopComment,
    CommentReplyItem,
    BottomOperator,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const commentReply = computed(() => store.state.comment.commentReplyList);
    watch(
      () => route.query.id,
      async (newId) => {
        store.commit("comment/changeCommentId", newId);
        await store.dispatch("comment/getCommentDetail", newId);
        await store.dispatch("comment/getCommentReply", newId);
      }
    );
    onMounted(async () => {
      const id = route.query.id;
      store.commit("comment/changeCommentId", id);
      await store.dispatch("comment/getCommentDetail", id);
      await store.dispatch("comment/getCommentReply", id);
    });
    return { commentReply };
  },
};
</script>

<style>
</style>