<template>
  <div class="mycomment">
    <div class="mycomment__top">
      <div class="mycomment__top__avatar">
        <img :src="$userInfo.avatar" />
      </div>
      <div class="mycomment__top__input">
        <van-field
          type="textarea"
          v-model="commentValue"
          placeholder="输入您的评论"
          rows="2"
          autosize
        />
      </div>
    </div>
    <div class="mycomment__bottom">
      <van-button
        type="primary"
        size="mini"
        :color="$userSetting.theme_color"
        :disabled="commentValue ? false : true"
        class="mycomment__bottom__btn"
        @click="toPublish"
        >发表评论</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const commentValue = ref("");
    const toPublish = async () => {
      if (commentValue.value) {
        await store.dispatch("comment/addComment", {
          news_id: Number(route.query.id),
          reply_comment_id: null,
          content: commentValue.value,
        });
      }
      await store.dispatch("comment/getCommentsList", route.query.id);
      commentValue.value = "";
    };
    return { commentValue, toPublish };
  },
};
</script>

<style lang="less" scoped>
.mycomment {
  &__top {
    display: flex;
    &__avatar {
      flex: 1;
      max-width: 100px;
      min-width: 80px;
      max-height: 100px;
      min-height: 80px;
      text-align: center;
      img {
        width: 60%;
        height: 60%;
        border-radius: 50%;
      }
    }
    &__input {
      flex: 4;
    }
  }
  &__bottom {
    text-align: right;
    margin-right: 15px;
    &__btn {
      border-radius: 5px;
      letter-spacing: 1px;
    }
  }
}
</style>