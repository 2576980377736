<template>
  <div class="navbar">
    <van-nav-bar :title="newsDetail.title">
      <template #left>
        <van-icon
          name="arrow-left"
          size="18"
          :color="$userSetting ? $userSetting.theme_color : 'red'"
          @click="onClickLeft"
        />
      </template>
      <template #right>
        <van-icon
          name="ellipsis"
          size="18"
          @click="showShare = true"
          :color="$userSetting ? $userSetting.theme_color : 'red'"
        />
      </template>
    </van-nav-bar>
  </div>
  <!-- 新闻区 -->
  <van-pull-refresh v-model="loading" @refresh="onRefresh">
    <NewsContent :newsDetail="newsDetail" />
    <van-divider>正文结束</van-divider>
    <!-- 评论区 -->
    <div class="newscomment">
      <div class="newscomment__title" v-if="$userInfo || newsComments[0]">
        评论区
      </div>
      <NewsCommentInput v-if="$userInfo" />
      <div class="newscomment__tip" v-if="!isShowComment" @click="showComment">
        查看评论<van-icon name="arrow" />
      </div>
      <NewsComment :commentsList="newsComments" v-else />
    </div>
  </van-pull-refresh>
  <!-- footer -->
  <NewsOperate :newsDetail="newsDetail" />
  <van-share-sheet
    v-model:show="showShare"
    title="立即分享给好友"
    :options="options"
    @select="onSelect"
  />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NewsContent from "./components/NewsContent.vue";
import NewsCommentInput from "../../components/comments/InputComment.vue";
import NewsComment from "../../components/comments/CommentItem.vue";
import NewsOperate from "./components/NewsOperate.vue";
import { Toast } from "vant";

// 分享面板
const useShareSheetEffect = () => {
  const showShare = ref(false);
  const options = [
    { name: "微信", icon: "wechat" },
    { name: "微博", icon: "weibo" },
    { name: "复制链接", icon: "link" },
    { name: "分享海报", icon: "poster" },
  ];
  const onSelect = (option) => {
    Toast(option.name);
    showShare.value = false;
  };
  return { showShare, options, onSelect };
};

// 下拉刷新
const usePullRefreshEffect = () => {
  const loading = ref(false);
  const onRefresh = () => {
    setTimeout(() => {
      Toast("刷新成功");
      loading.value = false;
    }, 1000);
  };
  return { loading, onRefresh };
};

export default {
  components: {
    NewsContent,
    NewsCommentInput,
    NewsComment,
    NewsOperate,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const onClickLeft = () => history.back();
    const newsDetail = computed(() => store.state.news.newsDetail);
    const newsComments = computed(() => store.state.comment.commentsList);
    const isShowComment = ref(false);
    const showComment = async () => {
      await store.dispatch("comment/getCommentsList", route.query.id);
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        isShowComment.value = true;
        Toast.clear();
      }, 500);
    };
    const { showShare, options, onSelect } = useShareSheetEffect();
    const { loading, onRefresh } = usePullRefreshEffect();

    onMounted(async () => {
      const id = route.query.id;
      store.commit("news/changeNewsId", id);
      await store.dispatch("news/getNewsDetailList", id);
      await store.dispatch("news/getNewsLikeAndCollect", id);
    });
    return {
      onClickLeft,
      newsDetail,
      newsComments,
      isShowComment,
      showComment,
      options,
      onSelect,
      showShare,
      loading,
      onRefresh,
    };
  },
};
</script>

<style lang="less" scoped>
.navbar {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.newscomment {
  margin-bottom: 60px;
  &__title {
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #f4f4f4;
  }
  &__tip {
    color: #999;
    font-size: 12px;
    text-align: center;
    padding: 10px;
  }
}
</style>
