<template>
  <div class="detail-wrap">
    <div class="detail-wrap__title">{{ newsDetail.title }}</div>
    <div class="detail-wrap__time">
      {{ date }}
    </div>
    <div class="detail-wrap__content" v-html="content"></div>
  </div>
</template>

<script>
import miment from "miment";
import { ref, watch, toRaw, getCurrentInstance } from "vue";
export default {
  props: ["newsDetail"],
  setup(props) {
    const date = ref(
      miment()
        .distance(Number(props.newsDetail.publish_time))
        .format("YYYY年MM月DD日")
    );
    const content = ref("");
    const userSetting =
      getCurrentInstance().appContext.config.globalProperties.$userSetting;
    watch(
      () => props.newsDetail,
      (newDetail) => {
        content.value = toRaw(newDetail).content;
        date.value = miment()
          .distance(Number(props.newsDetail.publish_time))
          .format("YYYY年MM月DD日");
        if (userSetting.use_mode == 2) {
          const imgReg = /<img.*?(?:>|\/>)/gi; //定义正则表达式（拿到img标签所有值）
          const videoReg = /<video.*?(?:>|\/>)/gi; //定义正则表达式（拿到video标签所有值）
          const deImgArray = content.value.match(imgReg); //使用正则表达式，拿到的是数组
          const deVideoArray = content.value.match(videoReg); //使用正则表达式，拿到的是数组
          const replace =
            "<div style='font-size: 80%;font-weight: bold;color: #c8c8c8;height: 150px;background: #f4f4f4;display: flex;justify-content: center;align-items: center;'>（省流模式不显示图片视频）</div>";
          if (deImgArray != null && deImgArray != undefined) {
            deImgArray.forEach((vm) => {
              content.value = content.value.replace(vm, replace);
            });
          }
          if (deVideoArray != null && deVideoArray != undefined) {
            deVideoArray.forEach((vm) => {
              content.value = content.value.replace(vm, replace);
            });
          }
        }
      }
    );

    return { date, content };
  },
};
</script>

<style lang="less" scoped>
.detail-wrap {
  margin: 20px 23px;
  &__title {
    line-height: 40px;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
  }
  &__time {
    color: #999;
    text-align: center;
    margin: 10px 0;
    font-size: 80%;
  }
}
</style>

<style lang="less">
.detail-wrap__content {
  font-size: 16px;
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  form,
  li {
    margin: 10px 0 20px 0;
    line-height: 1.71;
    br {
      line-height: 0px;
    }
  }
  .pgc-h-arrow-right {
    font-weight: bold;
  }
  .pgc-img {
    margin: 18px 0;
  }
  img,
  video {
    max-width: 100%;
    display: block;
    border-radius: 4px;
    margin: 0 auto 8px;
  }
}
</style>