<template>
  <van-tabbar class="wrap">
    <van-field
      v-model="comment"
      :placeholder="$userSetting?'回复该评论':'您还没有登录'"
      @keyup.enter="addComment"
    />
  </van-tabbar>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Dialog, Toast } from "vant";

export default {
  setup() {
    const store = useStore();
    const comment = ref("");
    const addComment = () => {
      if (comment.value) {
        Dialog.confirm({
          title: "评论",
          message: "你确定要评论吗？",
        })
          .then(() => {
            store
              .dispatch("comment/addComment", {
                news_id: Number(store.state.news.newsId),
                reply_comment_id: Number(store.state.comment.commentId),
                content: comment.value,
              })
              .then(async (res) => {
                if (res) {
                  // 获得回复列表
                  await store.dispatch(
                    "comment/getCommentReply",
                    store.state.comment.commentId
                  );
                  comment.value = "";
                  Toast.success("评论成功");
                }
              });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast("评论不能为空");
      }
    };
    return { comment, addComment };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 92%;
    .van-cell{
      border-radius: 20px;
    }
}
</style>