<template>
  <van-nav-bar title="新闻首页">
    <template #left>
      <van-icon
        name="manager-o"
        size="18"
        @click="$userInfo ? toMy() : toLogin()"
        :color="$userSetting ? $userSetting.theme_color : 'red'"
      />
    </template>
    <template #right>
      <div class="bar__right" v-show="active" @click="toLove">
        <van-icon
          name="like"
          size="20"
          :color="$userSetting ? $userSetting.theme_color : 'red'"
          v-show="is_love"
        />
        <van-icon
          name="like-o"
          size="20"
          :color="$userSetting ? $userSetting.theme_color : 'red'"
          v-show="!is_love"
        />
      </div>
    </template>
  </van-nav-bar>
</template>

<script>
import { computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast, Dialog } from "vant";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const is_love = computed(() => store.state.news.love);
    const active = computed(() => store.state.news.active);
    const userInfo =
      getCurrentInstance().appContext.config.globalProperties.$userInfo;
    // 设置关注该频道(未完成)
    const toLove = () => {
      Dialog.confirm({
        message: is_love.value ? "您要取消关注该频道？" : "你要关注该频道吗？",
      }).then(() => {
        store.dispatch("news/postNewsCateLikes");
      });
    };
    const toMy = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        router.push({
          name: "my",
          params: {
            id: userInfo.id,
          },
        });
        Toast.clear();
      }, 400);
    };
    const toLogin = () => {
      Toast.loading({
        message: "您还没登陆...",
        forbidClick: true,
      });
      setTimeout(() => {
        router.push({ name: "login" });
        Toast.clear();
      }, 400);
    };
    return { is_love, active, toLove, toMy, toLogin };
  },
};
</script>

<style lang="less" scoped>
.bar__right {
  p {
    color: red;
    font-size: 12px;
    margin: -8px 0;
  }
}
</style>