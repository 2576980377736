<template>
  <div class="comment">
    <div class="comment__avatar">
      <img :src="comment.user_avatar" />
    </div>
    <div class="comment__detail">
      <div class="comment__detail__top">
        <div class="comment__detail__top__name van-ellipsis">
          {{ comment.user_nickname }}
        </div>
      </div>
      <div class="comment__detail__content">{{ comment.content }}</div>
    </div>
  </div>
  <div class="detail">
    <div class="detail__item">{{ comment.comment_count }}回复</div>
    |
    <div class="detail__item">{{ comment.like_count }}赞</div>
    |
    <div class="detail__item">{{ comment.dislike_count }}踩</div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const comment = computed(() => store.state.comment.commentDetail);
    const commentsList = computed(() => store.state.comment.commentsList);
    return { comment, commentsList };
  },
};
</script>

<style lang="less" scoped>
.comment {
  display: flex;
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  letter-spacing: 0.5px;
  &__avatar {
    flex: 1;
    text-align: right;
    padding-right: 10px;
    min-width: 30px;
    max-width: 50px;
    min-height: 30px;
    max-height: 50px;
    img {
      width: 65%;
      height: 65%;
      border-radius: 50%;
    }
  }
  &__detail {
    flex: 3;
    &__top {
      margin: 12px 0;
      font-size: 13px;
      &__name {
        font-weight: 550;
        color: #00a8ff;
        width: 30px;
      }
    }
    &__content {
      line-height: 1.2;
      font-size: 14px;
    }
  }
}
.detail {
  color: #bbb;
  display: flex;
  margin: 15px 20px;
  font-size: 13px;
  letter-spacing: 1px;
  &__item {
    padding: 0 5px;
  }
}
</style>